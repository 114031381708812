import { useFormContext } from "react-hook-form";
import { Checkbox } from "@/components/ui/checkbox";
import {
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form";
import RequiredMarker from "@/components/common/required-marker";
import StyledInput from "@/components/common/styled-input";
import InputPatternFormat from "@/components/common/input-pattern-format";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectValue,
} from "@/components/ui/select";
import StyledSelectTrigger from "@/components/common/styled-select-trigger";
import { Textarea } from "@/components/ui/textarea";
import { SelectNative } from "@/components/ui/select-native";
import { RadioGroup, RadioGroupItem } from "@/components/ui/radio-group";

function FormFields() {
  const {
    control,
    watch,
    trigger,
    formState: { isSubmitted },
  } = useFormContext<any>();

  const [leadType$] = watch(["leadType"]);

  const validateEmail = () => {
    if (isSubmitted) {
      trigger("emailAddress");
    }
  };

  const validatePhoneNumber = () => {
    if (isSubmitted) {
      trigger("phoneNumber");
    }
  };

  const handleLeadTypeChange = (v: string | undefined) => {
    return v;
  };

  return (
    <>
      {/* NAME */}
      <FormField
        control={control}
        name="name"
        render={({ field, fieldState }) => (
          <FormItem className="mb-4">
            <FormLabel className="text-inherit">
              <RequiredMarker>Name</RequiredMarker>
            </FormLabel>
            <FormControl>
              <StyledInput {...field} {...fieldState} />
            </FormControl>
            <FormMessage className="font-normal" />
          </FormItem>
        )}
      />
      <div className="text-xs text-yellow-600 pb-2">
        Only one contact method required, an email or phone number.
      </div>

      {/* PHONE */}
      <div className="sm:flex gap-4">
        <div className="grow basis-0">
          <FormField
            control={control}
            name="phoneNumber"
            render={({ field, fieldState }) => (
              <FormItem className="mb-4">
                <FormLabel className="text-inherit">
                  <RequiredMarker className="text-yellow-600">
                    Phone Number
                  </RequiredMarker>
                </FormLabel>
                <FormControl>
                  <InputPatternFormat
                    format="+1 (###) ###-####"
                    {...field}
                    {...fieldState}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                      field.onChange(e.target.value);
                      validateEmail();
                    }}
                  />
                </FormControl>
                <FormMessage className="font-normal" />
              </FormItem>
            )}
          />
        </div>
        <div className="grow basis-0">
          <FormField
            control={control}
            name="phoneType"
            render={({ field, fieldState }) => {
              return (
                <FormItem className="mb-4">
                  <FormLabel className="text-inherit">
                    <RequiredMarker className="text-yellow-600">
                      Phone Type
                    </RequiredMarker>
                  </FormLabel>
                  <Select value={field.value} onValueChange={field.onChange}>
                    <FormControl>
                      <StyledSelectTrigger {...field} {...fieldState}>
                        <SelectValue placeholder="Select" />
                      </StyledSelectTrigger>
                      {/* <SelectTrigger className="bg-neutral-200 shadow-none focus:ring-black focus:ring-2">
                        <SelectValue placeholder="Select" />
                      </SelectTrigger> */}
                    </FormControl>
                    <SelectContent>
                      <SelectItem value="MOBILE">Mobile</SelectItem>
                      <SelectItem value="HOME">Home</SelectItem>
                      <SelectItem value="OFFICE">Office</SelectItem>
                      <SelectItem value="OTHER">Other</SelectItem>
                    </SelectContent>
                  </Select>
                  <FormMessage className="font-normal" />
                </FormItem>
              );
            }}
          />
        </div>
      </div>

      {/* EMAIL */}
      <FormField
        control={control}
        name="emailAddress"
        render={({ field, fieldState }) => (
          <FormItem className="mb-4">
            <FormLabel className="text-inherit">
              <RequiredMarker className="text-yellow-600">Email</RequiredMarker>
            </FormLabel>
            <FormControl>
              <StyledInput
                {...field}
                {...fieldState}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  field.onChange(e.target.value);
                  validatePhoneNumber();
                }}
              />
            </FormControl>
            <FormMessage className="font-normal" />
          </FormItem>
        )}
      />
      {/* ZIP */}
      <FormField
        control={control}
        name="zipcode"
        render={({ field, fieldState }) => (
          <FormItem className="mb-4">
            <FormLabel className="text-inherit">Zip Code</FormLabel>
            <FormControl>
              <StyledInput {...field} {...fieldState} />
            </FormControl>
            <FormMessage className="font-normal" />
          </FormItem>
        )}
      />
      {/* BEDROOMS */}
      <FormField
        control={control}
        name="bedrooms"
        render={({ field }) => (
          <FormItem className="mb-4">
            <FormLabel>
              <RequiredMarker>Bedrooms</RequiredMarker>
            </FormLabel>

            <FormControl>
              <RadioGroup
                onValueChange={field.onChange}
                defaultValue={field.value}
                className=" text-sm"
              >
                <div className="flex items-center space-x-2">
                  <RadioGroupItem value="0" id="r0" />
                  <label className="cursor-pointer" htmlFor="r0">
                    Studio
                  </label>
                </div>
                <div className="flex items-center space-x-2">
                  <RadioGroupItem value="1" id="r1" />
                  <label className="cursor-pointer" htmlFor="r1">
                    1 Bedroom
                  </label>
                </div>
                <div className="flex items-center space-x-2">
                  <RadioGroupItem value="2" id="r2" />
                  <label className="cursor-pointer" htmlFor="r2">
                    2 Bedroom
                  </label>
                </div>
                <div className="flex items-center space-x-2">
                  <RadioGroupItem value="3" id="r3" />
                  <label className="cursor-pointer" htmlFor="r3">
                    3 Bedroom
                  </label>
                </div>
              </RadioGroup>
            </FormControl>
            <FormMessage />
          </FormItem>
        )}
      />
      {/* LEADTYPE */}
      <FormField
        control={control}
        name="leadType"
        render={({ field, fieldState }) => {
          return (
            <FormItem className="mb-4">
              <FormLabel className="text-inherit">
                <RequiredMarker>
                  Are you a licensed real estate agent or broker?
                </RequiredMarker>
              </FormLabel>
              <Select
                value={field.value}
                onValueChange={(v) => {
                  handleLeadTypeChange(v);
                  field.onChange(v);
                }}
              >
                <FormControl>
                  <StyledSelectTrigger {...field} {...fieldState}>
                    <SelectValue />
                  </StyledSelectTrigger>
                </FormControl>
                <SelectContent>
                  <SelectItem value="">Select</SelectItem>
                  <SelectItem value="REPRESENTATIVE">Yes</SelectItem>
                  <SelectItem value="TENANT">No</SelectItem>
                </SelectContent>
              </Select>
              <FormMessage className="font-normal" />
            </FormItem>
          );
        }}
      />
      {/* BROKER COMPANY */}
      {leadType$ === "REPRESENTATIVE" && (
        <FormField
          control={control}
          name="brokerCompany"
          render={({ field, fieldState }) => (
            <FormItem className="mb-4">
              <FormLabel className="text-inherit">
                <RequiredMarker>Brokerage Firm</RequiredMarker>
              </FormLabel>
              <FormControl>
                <StyledInput {...field} {...fieldState} />
              </FormControl>
              <FormMessage className="font-normal" />
            </FormItem>
          )}
        />
      )}
      {/* COMMENT */}
      <FormField
        control={control}
        name="source"
        render={({ field }) => (
          <FormItem className="mb-4">
            <FormLabel>Source</FormLabel>
            <FormControl>
              <SelectNative
                items={[
                  { label: "Select", value: "" },
                  { label: "Street Easy", value: "Street Easy" },
                  { label: "Google", value: "Google" },
                  { label: "News", value: "News" },
                  { label: "Instagram", value: "Instagram" },
                  { label: "Signs", value: "Signs" },
                  { label: "Broker", value: "Broker" },
                ]}
                // className="bg-select-arrow"
                // className="text-base resize-none bg-neutral-200 border-neutral-200 border-2 focus-visible:border-black focus-visible:ring-0 shadow-none outline-none "
                {...field}
              />
            </FormControl>
            <FormMessage />
          </FormItem>
        )}
      />
      {/* COMMENT */}
      <FormField
        control={control}
        name="comments"
        render={({ field }) => (
          <FormItem className="mb-4">
            <FormLabel>Comments</FormLabel>
            <FormControl>
              <Textarea
                className="text-base resize-none bg-neutral-200 border-neutral-200 border-2 focus-visible:border-black focus-visible:ring-0 shadow-none outline-none "
                {...field}
              />
            </FormControl>
            <FormMessage />
          </FormItem>
        )}
      />
      {/* TEXTINGALLOWED */}
      <FormField
        control={control}
        name="textingAllowed"
        render={({ field }) => (
          <FormItem className="mb-4 flex flex-row items-center space-x-2 space-y-0 ">
            <FormControl>
              <Checkbox
                className="w-5 h-5 border-2 mt-1 shadow-none self-start"
                checked={field.value}
                onCheckedChange={field.onChange}
              />
            </FormControl>
            <FormLabel className="text-inheritly leading-2 font-normal">
              I agree to receive calls and text messages (e.g. general property
              pricing updates and new listings, no more than once per week) and
              to engage in text conversations with the real estate agent. Msg &
              data rates may apply. Unsubscribe at anytime by replying STOP.
            </FormLabel>
            <FormMessage className="font-normal" />
          </FormItem>
        )}
      />
    </>
  );
}

export default FormFields;
