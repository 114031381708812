import * as React from "react";

import { cn } from "@/lib/utils";

export type Item = {
  label: string | number | undefined;
  value: string | number | undefined;
};

export interface SelectProps
  extends React.SelectHTMLAttributes<HTMLSelectElement> {
  items: Item[];
}

const SelectNative = React.forwardRef<HTMLSelectElement, SelectProps>(
  ({ className, items, ...props }, ref) => {
    return (
      <select
        className={cn(
          "bg-no-repeat [background-position-y:center] appearance-none flex h-9 w-full rounded-md border border-input bg-transparent px-3 py-1 text-sm shadow-sm transition-colors file:border-0 file:bg-transparent file:text-sm file:font-medium placeholder:text-muted-foreground focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-ring disabled:cursor-not-allowed disabled:opacity-50",
          className,
          "bg-select-arrow"
        )}
        ref={ref}
        {...props}
        style={{ backgroundPositionX: "calc(100% - 12px)" }}
      >
        {items.map((item) => (
          <option key={item.value} value={item.value}>
            {item.label}
          </option>
        ))}
      </select>
    );
  }
);
SelectNative.displayName = "SelectNative";

export { SelectNative };
