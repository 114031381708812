import { useState, useEffect } from "react";

function usePostMessage() {
  const [message, setMessage] = useState<any>();

  useEffect(() => {
    const messageHandler = (event: MessageEvent) => {
      if (event.origin && event.data.key) {
        console.log("[child] received message: ", event.data, event.origin);
        setMessage(event.data);
      }
    };

    window.addEventListener("message", messageHandler);
    console.log("[child] listening...");
    return () => {
      window.removeEventListener("message", messageHandler);
    };
  }, []);

  return { message };
}

export default usePostMessage;
