import { yupResolver } from "@hookform/resolvers/yup";
import { ActionFunctionArgs, redirect, useLoaderData } from "react-router";
import { LoaderData } from "./loader";
import { useFetcher } from "react-router-dom";
import { useForm } from "react-hook-form";
import { Form } from "@/components/ui/form";
import { customFormsSchema } from "@/lib/validation";
import FormFields from "./form-fields";
import { ReloadIcon } from "@radix-ui/react-icons";
import { Button } from "@/components/ui/button";
import usePostMessage from "@/lib/use-post-message";
import { useEffect, useRef, useState } from "react";
import { createLead } from "@/lib/api/create-lead";
import * as Sentry from "@sentry/react";

function CustomForm() {
  const { campaign } = useLoaderData() as unknown as LoaderData;

  const fetcher = useFetcher();

  const [attribution, setAttribution] = useState<any>();

  const { message } = usePostMessage();

  // const errors = fetcher.data?.errors;

  const isLoading =
    fetcher.state === "submitting" || fetcher.state === "loading";

  const formInstance = useForm({
    defaultValues: {
      bedrooms: "",
      // bathrooms: "",
      campaignRef: campaign.id,
      comments: "",
      emailAddress: "",
      name: "",
      phoneNumber: "",
      phoneType: "",
      // priceMax: "",
      // priceMin: "",
      // sqftMax: "",
      // sqftMin: "",
      source: "",
      textingAllowed: false,
      zipcode: "",
      gclid: "",
      utmCampaign: "",
      utmContent: "",
      utmMedium: "",
      utmSource: "",
    },
    resolver: yupResolver(customFormsSchema),
  });

  const {
    getValues,
    handleSubmit,
    register,
    formState: { errors: formErrors },
    reset,
    watch,
  } = formInstance;

  const onSubmit = (formData: any) => {
    const { phoneType, ...payload } = formData;

    const model = {
      ...payload,
    };

    if (formData.phoneNumber && typeof formData.phoneNumber === "string") {
      Object.assign(model, {
        phoneNumber: formData.phoneNumber.replace(/[^\d+]/g, ""),
      });
    }
    if (phoneType) {
      Object.assign(model, { phoneType });
    }

    fetcher.submit(model, { method: "post" });
  };

  // Mutation observer to notify host window
  const ref = useRef<HTMLDivElement>(null);

  const observerCallback = (mutationList: MutationRecord[]) => {
    if (Array.isArray(mutationList)) {
      // (mutationList).forEach((m) => console.log(m));
      const nodeChange = mutationList.some(
        (mutation) => mutation.type === "childList"
      );

      if (nodeChange) {
        // if (window.parent !== window.self) {
        console.log(
          "[child] sending message to parent.",
          ref.current?.scrollHeight,
          ref.current?.clientHeight,
          ref.current?.offsetHeight,
          ref.current?.getBoundingClientRect().height
        );

        window.parent.postMessage({ size: ref.current?.scrollHeight }, "*");
        // }
      }
    }
  };
  const observerRef = useRef(new MutationObserver(observerCallback));

  // const observer = useMemo(() => {
  //   return new MutationObserver(observerCallback);
  // }, []);

  useEffect(() => {
    const observer = observerRef.current;

    if (ref.current) {
      observer.observe(ref.current, { childList: true, subtree: true });
    }

    return () => {
      observer.disconnect();
    };
  }, []);

  useEffect(() => {
    if (window.parent !== window.self) {
      console.log("[child] sending message to parent.");
      window.parent.postMessage({ state: "ready" }, "*");
    }
  }, []);

  useEffect(() => {
    if (message && message.type === "attribution") {
      const { key, type, ...rest } = message;
      console.log({ key, type, message: rest });
      setAttribution(rest);
    }
  }, [message]);

  useEffect(() => {
    if (attribution) {
      reset({
        ...getValues(),
        ...attribution,
      });
    }
  }, [attribution, getValues, reset]);

  return (
    <div ref={ref} className="px-4 flex flex-col">
      <textarea
        readOnly
        className="text-xs absolute top-0 left-0 [resize:both] border-solid border-2 border-indigo-600 whitespace-pre font-mono"
        value={JSON.stringify(formErrors, null, 2)}
      />
      <textarea
        readOnly
        className="w-[300px] text-xs absolute top-0 right-0 [resize:vertical] border-solid border-2 border-indigo-600 whitespace-pre font-mono"
        value={JSON.stringify(watch(), null, 2)}
      />

      {/* <pre className="text-xs">{JSON.stringify(attribution, null, 2)}</pre> */}
      <Form {...formInstance}>
        <fetcher.Form method="post" onSubmit={handleSubmit(onSubmit)}>
          <input type="hidden" readOnly {...register("campaignRef")} />
          <input type="hidden" readOnly {...register("gclid")} />
          <input type="hidden" readOnly {...register("utmCampaign")} />
          <input type="hidden" readOnly {...register("utmContent")} />
          <input type="hidden" readOnly {...register("utmMedium")} />
          <input type="hidden" readOnly {...register("utmSource")} />
          <FormFields />
          <div className="mb-4">
            <Button className="flex-1" type="submit" disabled={isLoading}>
              {isLoading && (
                <ReloadIcon className="mr-2 h-4 w-4 animate-spin" />
              )}
              Submit
            </Button>
          </div>
        </fetcher.Form>
      </Form>
    </div>
  );
}

export async function action({ request, params }: ActionFunctionArgs) {
  const formData = await request.formData();

  const data = [...formData.entries()].reduce((model, entry) => {
    return {
      ...model,
      [entry[0]]: entry[1],
    };
  }, {});

  const response = await createLead(data);

  if (!response.ok) {
    const res = await response?.json();
    Sentry.captureException(res);
    return { errors: { ...res }, status: response.status };
  }
  return redirect(`/forms/${params.id}/confirmation?q=${params.id}`);
}

export default CustomForm;
