import Root from "@/pages/root/root";
import NewGuest, { action as guestAction } from "@/pages/guest/new";
import OpenHouseCampaign, {
  action as openHouseAction,
} from "@/pages/open-house/campaign";
import FormCampaign from "@/pages/guest/campaign";
import * as Sentry from "@sentry/react";
import { HttpClient as HttpClientIntegration } from "@sentry/integrations";
import {
  createBrowserRouter,
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType,
} from "react-router-dom";
import Confirm from "@/pages/open-house/confirm";
import { loader as openHouseLoader } from "@/pages/open-house/loader";
import { loader as guestLoader } from "@/pages/guest/loader";
import { loader as openHouseConfirmLoader } from "@/pages/open-house/confirm";
import { loader as propertyLoader } from "@/pages/inquiry/loader";
import { loader as formsLoader } from "@/pages/forms/loader";
import { ErrorBoundary } from "@/components/common/error-boundary";
import OpenHouse from "@/pages/open-house/open-house";
import Guest from "@/pages/guest/guest";
import React from "react";
import Inquiry from "@/pages/inquiry";
import SplashPage from "@/components/common/splash-page";
import Property, { action as propertyAction } from "@/pages/inquiry/property";
import CancelOpenHouseAttendance, {
  action as cancelAttendanceAction,
  loader as cancelAttendanceLoader,
} from "@/pages/inquiry/cancel-open-house-attendance";
import Confirmation from "@/pages/inquiry/confirmation";
import CustomForm, {
  action as customFormAction,
} from "@/pages/forms/custom-form";
import Forms from "@/pages/forms";

const monitor =
  import.meta.env.MODE === "production" || import.meta.env.MODE === "staging";

const sampleRate = import.meta.env.MODE === "production" ? 0.25 : 1.0;

if (monitor) {
  Sentry.init({
    environment: import.meta.env.MODE,
    dsn: "https://d4514ffa56ac459e895c7891ba07e2bf@o4505570587770880.ingest.sentry.io/4505570896183296",
    integrations: [
      new Sentry.BrowserTracing({
        routingInstrumentation: Sentry.reactRouterV6Instrumentation(
          React.useEffect,
          useLocation,
          useNavigationType,
          createRoutesFromChildren,
          matchRoutes
        ),
      }),
      new HttpClientIntegration({
        // This array can contain tuples of `[begin, end]` (both inclusive),
        // single status codes, or a combination of both.
        // default: [[500, 599]]
        failedRequestStatusCodes: [
          [400, 404],
          [500, 599],
        ],

        // This array can contain Regexes, strings, or a combination of both.
        // default: [/.*/]
        failedRequestTargets: [
          "https://apiv2.spaceit.com",
          "https://stageapiv2.spaceit.com",
        ],
      }),
    ],
    tracesSampleRate: sampleRate,
  });
}

const sentryCreateBrowserRouter =
  Sentry.wrapCreateBrowserRouter(createBrowserRouter);

const router = sentryCreateBrowserRouter([
  {
    path: "/",
    element: <Root />,
    errorElement: <ErrorBoundary />,
  },
  {
    path: "guest",
    element: <Guest />,
    errorElement: <ErrorBoundary />,
    children: [
      {
        index: true,
        element: <SplashPage />,
      },
      {
        path: ":id",
        loader: guestLoader,
        element: <FormCampaign />,
      },
      {
        path: ":id/new",
        action: guestAction,
        loader: guestLoader,
        element: <NewGuest />,
      },
    ],
  },
  {
    path: "open-house",
    element: <OpenHouse />,
    errorElement: <ErrorBoundary />,
    children: [
      {
        index: true,
        element: <SplashPage />,
      },
      {
        path: "confirm",
        element: <Confirm />,
        loader: openHouseConfirmLoader,
      },
      {
        path: ":id",
        action: openHouseAction,
        loader: openHouseLoader,
        element: <OpenHouseCampaign />,
      },
    ],
  },
  {
    path: "contact",
    element: <Inquiry />,
    errorElement: <ErrorBoundary />,
    children: [
      {
        index: true,
        element: <SplashPage />,
      },
      {
        path: ":id/cancel",
        element: <CancelOpenHouseAttendance />,
        loader: cancelAttendanceLoader,
        action: cancelAttendanceAction,
      },
      {
        path: ":id/confirmation",
        element: <Confirmation />,
        loader: propertyLoader,
      },
      {
        path: ":id",
        action: propertyAction,
        loader: propertyLoader,
        element: <Property />,
      },
    ],
  },
  {
    path: "forms",
    element: <Forms />,
    errorElement: <ErrorBoundary />,
    children: [
      {
        index: true,
        element: <SplashPage />,
      },
      {
        path: ":id/confirmation",
        element: <div>Confirmation</div>,
        loader: formsLoader,
      },
      {
        path: ":id",
        action: customFormAction,
        loader: formsLoader,
        element: <CustomForm />,
      },
    ],
  },
]);

export default router;
